import {
  get,
  replace,
  map,
  reduce,
  keys,
  values,
  sum,
  find,
  filter,
  every,
  isEqual,
} from './nodash';
import {
  SHOPIFY_WEIGHT_UNIT_POUNDS,
  GALLON_RESTOCK_FEE,
  SUPPLY_RESTOCK_FEE,
  STANDARD_PACKAGE_DIMENSIONS,
  SHOPIFY_PERCENTAGE_VALUE_TYPE,
  WALLCOVERINGS_ROLL_RESTOCK_FEE,
} from './constants';
import {
  isPaint,
  isSupply,
  isWallcovering,
  getVariantGallonCount,
  isVariantHalfGal,
} from './product';
import isBlank from './isBlank';
import { PAINT_SUPPLY_DISCOUNT_THRESHOLD } from './cart/calculateCartDiscounts';

export const getLineItemByVariantId = (variantId, order) => {
  return find(
    (z) => isEqual(variantId, get('variant.id', z)),
    get('lineItems', order)
  );
};

export const getGallonsReturned = (order, qtyMap) => {
  return reduce(
    (mem, x) => {
      mem +=
        (isVariantHalfGal(get('variant', x))
          ? 1
          : getVariantGallonCount(get('variant', x))) * qtyMap[x.variant.id];
      return mem;
    },
    0,
    filter(
      isPaint,
      map(
        (x) => ({
          quantity: qtyMap[x],
          ...getLineItemByVariantId(x, order),
        }),
        keys(qtyMap)
      )
    )
  );
};

export const getUndiscountedReturnValue = (order, qtyMap) => {
  return reduce(
    (mem, x) => {
      const item = getLineItemByVariantId(x, order);
      mem += (item.originalUnitPrice ?? 0) * qtyMap[x];
      return mem;
    },
    0,
    keys(qtyMap)
  );
};

const getTotalDiscountOnOrder = (order) => {
  return order.lineItems.reduce((acc, item) => {
    acc += (item?.discountAllocations ?? []).reduce((acc, discount) => {
      acc += discount.allocatedAmount?.amount ?? 0;
      return acc;
    }, 0);
    return acc;
  }, 0);
};

export const getDiscountedReturnValue = (order, qtyMap) => {
  return reduce(
    (mem, x) => {
      const item = getLineItemByVariantId(x, order);
      const origPrice = item.originalUnitPrice ?? 0;
      const discount = (item.discountAllocations ?? []).reduce(
        (acc, discount) => {
          acc += discount.allocatedAmount?.amount;
          return acc;
        },
        0
      );

      mem += (origPrice - discount) * qtyMap[x];
      return mem;
    },
    0,
    keys(qtyMap)
  );
};

export const getRollsReturned = (order, qtyMap) => {
  return reduce(
    (mem, x) => {
      mem += qtyMap[x.variant.id];
      return mem;
    },
    0,
    filter(
      isWallcovering,
      map(
        (x) => ({
          quantity: qtyMap[x],
          ...getLineItemByVariantId(x, order),
        }),
        keys(qtyMap)
      )
    )
  );
};

export const hasSupplyItem = (order, qtyMap) =>
  filter((x) => {
    const item = getLineItemByVariantId(x, order);
    return qtyMap[x] > 0 && isSupply(item);
  }, keys(qtyMap)).length > 0;

export const calculatePaintDiscountFees = (order, qtyMap) => {
  const returnValue = getUndiscountedReturnValue(order, qtyMap);

  const isAppliedDiscount = order.discountApplications?.some(
    (x) =>
      x.targetSelection === 'ENTITLED' &&
      x.targetType === 'LINE_ITEM' &&
      x.allocationMethod === 'ACROSS'
  );

  const isPaintDiscounted = every((x) => {
    // Can't compare discountedUnitPrice to originalUnitPrice because the discountedUnitPrice is not always set, even when there is an ENTITLED discount
    const itemDiscount = (x.discountAllocations ?? []).reduce(
      (acc, discount) => {
        acc += discount.allocatedAmount?.amount;
        return acc;
      },
      0
    );
    return itemDiscount > 0;
  }, filter(isPaint, get('lineItems', order)));

  // get sum of originalUnitPrice for paint and supply items
  const undiscountedPaintSupplyTotal = get('lineItems', order).reduce(
    (acc, item) => {
      if (isPaint(item) || isSupply(item)) {
        acc += item.originalUnitPrice * item.quantity;
      }
      return acc;
    },
    0
  );

  // Do not apply discount adjustment if entire order is being returned
  if (returnValue >= undiscountedPaintSupplyTotal) {
    return 0;
  }

  const orderDiscount = getTotalDiscountOnOrder(order);

  const isDiscountThresholdMet =
    undiscountedPaintSupplyTotal >= PAINT_SUPPLY_DISCOUNT_THRESHOLD;

  const receivedPaintDiscount =
    isPaintDiscounted && isDiscountThresholdMet && isAppliedDiscount;

  const returnsUndoDiscount =
    receivedPaintDiscount &&
    undiscountedPaintSupplyTotal - returnValue <=
      PAINT_SUPPLY_DISCOUNT_THRESHOLD;

  return returnsUndoDiscount && returnValue > 0 ? orderDiscount : 0;
};

export const calculateRefundValue = (order, qtyMap) => {
  const gallonsReturned = getGallonsReturned(order, qtyMap);
  const rollsReturned = getRollsReturned(order, qtyMap);

  const hasSupplies = hasSupplyItem(order, qtyMap);

  const restockFees =
    gallonsReturned * GALLON_RESTOCK_FEE +
    rollsReturned * WALLCOVERINGS_ROLL_RESTOCK_FEE +
    (hasSupplies ? SUPPLY_RESTOCK_FEE : 0);
  const returnValues = calculateReturnValues(order, qtyMap);

  const totalProductReturn = sum(values(returnValues));
  const paintDiscountFees = calculatePaintDiscountFees(order, qtyMap);

  const subtotal = totalProductReturn - restockFees - paintDiscountFees;
  return subtotal < 0 ? 0 : subtotal;
};

export const getLineItemPriceWithOrderDiscount = (order, item) => {
  const orderDiscounts = get('discountApplications', order);
  const fullOrderDiscount = find(
    {
      allocationMethod: 'ACROSS',
      targetType: 'LINE_ITEM',
      targetSelection: 'ALL',
    },
    orderDiscounts
  );
  const fullOrderDiscountValue = get('value', fullOrderDiscount) || 0;
  switch (get('type', fullOrderDiscountValue)) {
    case SHOPIFY_PERCENTAGE_VALUE_TYPE:
      return (
        get('discountedUnitPrice', item) *
        ((100 - get('percentage', fullOrderDiscountValue)) / 100)
      );
    default:
      return get('discountedUnitPrice', item);
  }
};

export const calculateReturnValues = (order, qtyMap) => {
  return reduce(
    (mem, x) => {
      const item = getLineItemByVariantId(x, order);
      if (get('discountedUnitPrice', item)) {
        mem[x] = getLineItemPriceWithOrderDiscount(order, item) * qtyMap[x];
      }
      return mem;
    },
    {},
    keys(qtyMap)
  );
};

export const generateOrderReturnData = ({
  quantityMap,
  reasonMap,
  order,
  packages,
}) => {
  const orderReturn = {
    order_id: get('id', order),
    customer_id: get('customerId', order),
    order_number: replace('#', '', get('name', order)),
    email: get('email', order),
    refund_amount: calculateRefundValue(order, quantityMap),
  };

  const address = get('shippingAddress', order);
  const shippingAddress = {
    name: get('name', address),
    city: get('city', address),
    company_name: isBlank(get('company', address))
      ? null
      : get('company', address),
    state: get('provinceCode', address),
    address_line1: get('address1', address),
    address_line2: get('address2', address),
    postal_code: get('zip', address),
    residential: isBlank(get('company', address)),
    country_code: get('countryCodeV2', address),
    phone: get('phone', address)
      ? replace(/[()-\s]/g, '', get('phone', address))
      : replace(/[()-\s]/g, '', get('customerPhone', order)),
  };

  const shippingLabel = {
    ship_from: shippingAddress,
    packages,
    is_return_label: true,
    charge_event: 'on_carrier_acceptance',
  };

  const returnValues = calculateReturnValues(order, quantityMap);
  const orderReturnItems = filter(
    (x) => get('quantity', x) > 0,
    map((variantId) => {
      const lineItem = getLineItemByVariantId(variantId, order);
      return {
        variant_id: variantId,
        product_id: get('product.id', lineItem),
        product_type: get('product.productType', lineItem),
        title: `${get('product.title', lineItem)} - ${get(
          'variant.title',
          lineItem
        )}`,
        quantity: quantityMap[variantId],
        return_value: returnValues[variantId],
        reason: reasonMap[variantId],
      };
    }, keys(quantityMap))
  );

  return { orderReturn, shippingLabel, orderReturnItems };
};

export const calculateReturnItemsWeight = (order, quantityMap) => {
  return reduce(
    (mem, x) => {
      const lineItem = getLineItemByVariantId(x, order);
      if (
        isEqual(SHOPIFY_WEIGHT_UNIT_POUNDS, get('variant.weightUnit', lineItem))
      ) {
        mem += get('variant.weight', lineItem) * quantityMap[x];
      } else {
        mem += 0;
      }
      return mem;
    },
    0,
    keys(quantityMap)
  );
};

export const calculateInitialReturnItemsPackages = (
  order,
  quantityMap,
  packageItems
) => {
  // const weight = calculateReturnItemsWeight(order, quantityMap);
  // const packageCount = Math.ceil(weight / MAX_WEIGHT_PER_PACKAGE);

  const packages = [
    {
      dimensions: STANDARD_PACKAGE_DIMENSIONS,
      included: reduce(
        (mem, x) => {
          mem[x.id] = x.quantity;
          return mem;
        },
        {},
        packageItems
      ),
    },
  ];

  return packages;
};
