import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { head, get, isEqual, startCase, last } from '../lib/nodash';
import { Heading, Box, Button } from 'grommet';
import { FormNextLink } from 'grommet-icons';
import { format } from 'date-fns';
import loadable from '@loadable/component';

import { useStoryblokState } from '../lib/storyblok';
import BreadCrumbNav from '../components/BreadCrumbNav';
import Loading from '../components/Loading';
import Seo from '../components/Seo';
import { generateOrderReturnData } from '../lib/orderReturns';
import { setActiveModal } from '../state/ui/uiSlice';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';
import isBrowser from '../lib/isBrowser';
import useMenuOffset from '../components/useMenuOffset';

const FormOrderLookup = loadable(() =>
  import('../components/OrderReturns/FormOrderLookup')
);
const FormOrderReturnSelectItems = loadable(() =>
  import('../components/OrderReturns/FormOrderReturnSelectItems')
);
const FormOrderReturnPackages = loadable(() =>
  import('../components/OrderReturns/FormOrderReturnPackages')
);
const OrderReturns = loadable(() =>
  import('../components/OrderReturns/OrderReturns')
);

import {
  ORDER_RETURN_LOOKUP,
  GET_ORDER_RETURNS,
  CREATE_ORDER_RETURN,
} from '../queries/returnQueries';

const Returns = ({
  location,
  data: { lookup, productIssue, returnPolicy, returns, site },
}) => {
  const canonicalUrl = site.siteMetadata.siteUrl + location.pathname;
  const dispatch = useDispatch();
  const [step, setStep] = useState('lookup');
  const [returnType, setReturnType] = useState('return');
  const [acknowledged, setAcknowledged] = useState(false);
  const [checking, setChecking] = useState(false); // prevent flash between loading states
  const [quantityMap, setQuantityMap] = useState(null);
  const [reasonMap, setReasonMap] = useState(null);
  const { offsetFromTop } = useMenuOffset();

  const lookupComponents = usePageBodyComponents(useStoryblokState(lookup), {
    pad: 'none',
  });

  const productIssueModal = useStoryblokState(productIssue);
  const returnPolicyContent = usePageBodyComponents(
    useStoryblokState(returnPolicy),
    { pad: 'none' }
  );

  const story = useStoryblokState(returns);

  const [lookupOrderReturns, { data: returnData, loading: returnLoading }] =
    useLazyQuery(GET_ORDER_RETURNS, {
      onCompleted: (data) => {
        setChecking(false);
        if ((get('lookupOrderReturns', data) || []).length > 0) {
          setStep('status');
        } else {
          setStep('info');
        }
      },
    });

  const [lookupOrder, { data: orderData, loading: lookupLoading }] =
    useLazyQuery(ORDER_RETURN_LOOKUP, {
      onCompleted: (d) => {
        if (get('orderReturnLookup.order', d)) {
          lookupOrderReturns({
            variables: {
              order_number: get('orderReturnLookup.order.name', d),
              email: get('orderReturnLookup.order.email', d),
            },
          });
        } else {
          setChecking(false);
        }
      },
    });

  const [
    createReturn,
    { data: createdReturnData, loading: createdReturnLoading },
  ] = useMutation(CREATE_ORDER_RETURN);

  const isLoading = lookupLoading || returnLoading || checking;

  const order = get('orderReturnLookup.order', orderData);
  const lookupError = head(get('orderReturnLookup.userErrors', orderData));

  const orderReturns = get('lookupOrderReturns', returnData) || [];

  const returnRecord =
    get('createOrderReturnWithLabel.orderReturn', createdReturnData) ||
    last(orderReturns);

  const handleValues = useCallback(async (values) => {
    setChecking(true);
    await lookupOrder({ variables: values });
  });
  const handleAcknowledge = useCallback((type) => {
    setReturnType(type);
    setAcknowledged(true);
    setStep('choose');
  });
  const handleExchange = useCallback(() => {
    setReturnType('exchange');
  });
  const handleProductIssue = useCallback(() => {
    dispatch(setActiveModal(get('content', productIssueModal)));
  }, [productIssueModal]);
  const handleProductSelection = useCallback((value, reasonMap) => {
    isBrowser && window.scrollTo(0, 0);
    setQuantityMap(value);
    setReasonMap(reasonMap);
    setStep('package');
  });
  const handlePackages = async (packages) => {
    isBrowser && window.scrollTo(0, 0);
    const returnOrderPayload = generateOrderReturnData({
      quantityMap,
      reasonMap,
      order,
      packages,
    });
    await createReturn({ variables: returnOrderPayload });
    setStep('status');
  };

  const steps = [
    {
      id: 'lookup',
      label: 'Lookup Order',
    },
    {
      id: 'info',
      label: 'How It Works',
      disabled: !get('orderReturnLookup.order', orderData),
    },
    {
      id: 'choose',
      label: 'Select Items',
      disabled: !acknowledged && !returnRecord,
    },
    {
      id: 'package',
      label: 'Shipping Label',
      disabled: !quantityMap,
    },
    {
      id: 'status',
      label: 'Status',
      disabled: !returnRecord,
    },
  ];

  return (
    <Box
      pad={{ horizontal: 'large', vertical: `${offsetFromTop}px` }}
      margin={{ top: 'large', horizontal: 'auto' }}
      gap="medium"
      flex={true}
      fill
    >
      <Seo
        title={get('content.metafields.title', story) || get('title', story)}
        description={get('content.metafields.description', story)}
        image={get('content.seoImage.filename', story)}
        canonical={canonicalUrl}
      />
      <BreadCrumbNav
        steps={steps}
        current={step}
        onNavigate={(x) => setStep(x)}
      />
      {isLoading ? (
        <Box margin="auto" pad="xlarge">
          <Loading size="large" />
        </Box>
      ) : (
        <Box gap="medium">
          {!isEqual('lookup', step) && (
            <Box margin={{ bottom: 'medium' }}>
              <Heading level={1} size="small" margin={{ bottom: 'none' }}>
                {get('order_number', returnRecord)
                  ? `Order #${get('order_number', returnRecord)}`
                  : `${startCase(returnType)} items ${
                      get('orderReturnLookup.order.name', orderData)
                        ? `from order ${get(
                            'orderReturnLookup.order.name',
                            orderData
                          )}`
                        : ''
                    }`}
              </Heading>
              {get('orderReturnLookup.order.createdAt', orderData) && (
                <Heading level={5} margin="none">
                  Order placed{' '}
                  {format(
                    new Date(
                      get('orderReturnLookup.order.createdAt', orderData)
                    ),
                    'M/d/yy'
                  )}
                </Heading>
              )}
            </Box>
          )}

          <Box>
            {isEqual('lookup', step) && (
              <Box gap="medium">
                {lookupComponents}
                <FormOrderLookup onValues={handleValues} error={lookupError} />
              </Box>
            )}
            {isEqual('info', step) && (
              <Box>
                <Box>{returnPolicyContent}</Box>
                <Box direction="row-responsive" gap="small">
                  <Box width="medium">
                    <Button
                      secondary
                      icon={<FormNextLink />}
                      reverse
                      onClick={() => handleAcknowledge('return')}
                      label="Return"
                    />
                  </Box>
                  <Box width="medium">
                    <Button
                      secondary
                      icon={<FormNextLink />}
                      reverse
                      onClick={() => handleAcknowledge('exchange')}
                      label="Exchange"
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {isEqual('choose', step) && (
              <FormOrderReturnSelectItems
                order={order}
                onSubmit={handleProductSelection}
                onExchange={handleExchange}
                onProductIssue={handleProductIssue}
              />
            )}
            {isEqual('package', step) && (
              <FormOrderReturnPackages
                order={order}
                quantityMap={quantityMap}
                onSubmit={handlePackages}
                loading={createdReturnLoading}
              />
            )}
            {isEqual('status', step) && (
              <OrderReturns
                email={get('email', returnRecord)}
                orderNumber={get('order_number', returnRecord)}
                returnType={returnType}
                onReturnMore={() => setStep('choose')}
                location={location}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

Returns.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object,
};

export const query = graphql`
  query ReturnsPageContent {
    lookup: storyblokEntry(full_slug: { eq: "returns/lookup-order" }) {
      id
      name
      full_slug
      content
      internalId
    }
    productIssue: storyblokEntry(
      full_slug: { eq: "returns/product-issue-pop-up" }
    ) {
      id
      name
      full_slug
      content
      internalId
    }
    returnPolicy: storyblokEntry(full_slug: { eq: "returns/return-policy" }) {
      id
      name
      full_slug
      content
      internalId
    }
    returns: storyblokEntry(full_slug: { eq: "returns/" }) {
      id
      name
      full_slug
      content
      internalId
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default Returns;
