import gql from 'graphql-tag';

export const ORDER_RETURN_LOOKUP = gql`
  query OrderReturnLookup($number: String!, $email: String!) {
    orderReturnLookup(email: $email, number: $number) {
      order {
        id
        customerName
        customerId
        customerPhone
        email
        total
        subtotal
        name
        createdAt
        discountCode
        shippingAddress {
          address1
          address2
          city
          company
          provinceCode
          countryCodeV2
          name
          zip
          phone
        }
        discountApplications {
          allocationMethod
          targetType
          targetSelection
          value {
            __typename
            type
            amount
            percentage
          }
        }
        lineItems {
          id
          quantity
          discountAllocations {
            allocatedAmount {
              amount
            }
          }
          discountedUnitPrice
          discountedTotal
          originalUnitPrice
          originalTotal
          fulfillmentStatus
          requiresShipping
          variant {
            id
            title
            weightUnit
            weight
          }
          product {
            id
            title
            productType
          }
          image
        }
      }

      userErrors {
        title
        description
      }
    }
  }
`;

export const CREATE_ORDER_RETURN = gql`
  mutation CreateOrderReturn(
    $orderReturn: OrderReturnInput!
    $shippingLabel: ShippingLabelSingleAddressInput!
    $orderReturnItems: [OrderReturnItemInput]!
  ) {
    createOrderReturnWithLabel(
      orderReturn: $orderReturn
      shippingLabel: $shippingLabel
      orderReturnItems: $orderReturnItems
    ) {
      orderReturn {
        id
        email
        order_number
        shipping_label {
          id
        }
      }
      error
    }
  }
`;

export const GET_ORDER_RETURNS = gql`
  query LookupOrderReturns($email: String!, $order_number: String!) {
    lookupOrderReturns(email: $email, order_number: $order_number) {
      id
      email
      order_number
      status
      created_at
      updated_at
      shipping_label {
        id
        shipment_id
        pdf_url
        generic_url
      }
    }
  }
`;

export const GET_ORDER_RETURN = gql`
  query GetOrderReturn($id: ID!) {
    orderReturn(id: $id) {
      id
      email
      order_number
      status
      created_at
      updated_at
      refund_amount
      order_return_items {
        id
        quantity
        variant_id
        product_id
        title
        return_value
        reason
      }
      shipping_label {
        id
        shipment_id
        pdf_url
        generic_url
      }
    }
  }
`;
