import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Button, ThemeContext } from 'grommet';

const BreadCrumbNav = ({ steps = [], current, onNavigate }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box direction="row" wrap={true} gap="xsmall">
      {steps.map((x, i) => (
        <Box
          key={x.id}
          gap="xsmall"
          direction="row"
          focusIndicator={false}
          onClick={onNavigate && !x.disabled ? () => onNavigate(x.id) : null}
        >
          <Button
            style={{
              opacity: x.disabled ? 0.5 : 1,
              transition: '0.5s opacity',
              borderBottom:
                x.id === current
                  ? `${theme.global.borderSize.small} solid ${theme.global.colors.border}`
                  : 'none',
            }}
            disabled={x.disabled}
            key={x.id}
          >
            {x.label}
          </Button>
          {i + 1 !== steps.length && (
            <Text style={{ opacity: x.disabled ? 0.5 : 1 }} size="medium">
              /
            </Text>
          )}
        </Box>
      ))}
    </Box>
  );
};

BreadCrumbNav.propTypes = {
  steps: PropTypes.array.isRequired,
  current: PropTypes.string,
  onNavigate: PropTypes.func,
};

export default memo(BreadCrumbNav);
